@font-face {
  font-family: 'Noto Sans Thai UI';
  src: url(./NotoSansThaiUI-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Thai UI';
  src: url(./NotoSansThaiUI-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Thai UI';
  src: url(./NotoSansThaiUI-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans';
  src: url(./NotoSans-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans';
  src: url(./NotoSans-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans';
  src: url(./NotoSans-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

