#root {
  font-family: "Noto Sans Thai UI", Roboto, 'Noto Sans', sans-serif, BlinkMacSystemFont,"Segoe UI",,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

body {
  font-family: "Noto Sans Thai UI", Roboto, 'Noto Sans', sans-serif, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='file'] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.commentBox:before {
	content: '';
	position: absolute;
	top: 0;
	left: 15%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: #f3f3f3;
	border-top: 0;
	border-left: 0;
	margin-left: -10px;
	margin-top: -10px;
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

:focus {outline:none;}